import { Button, Radio, Space, Typography } from "antd";

import { InboxOutlined } from "@ant-design/icons";

import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Attachment, fetchInvoice, uploadNewDocumentVersion } from "../store/invoicesSlice";
import Dragger from "antd/es/upload/Dragger";
import { getFileName } from "../utils";
import { useAppDispatch } from "../store";
import { useState } from "react";
import { fetchAuditLogByAttachmentId } from "../store/auditLogSlice";
import { fetchEmail, fetchInvoices } from "../store/emailsSlice";

const { Text } = Typography;

interface Props {
  attachment: Attachment;
  isAttachment: boolean;
  renderedFile: string;
  changeVersion: Function;
  controlSize: SizeType;
  disabled: boolean;
}

function DocumentVersionsSection({ attachment, isAttachment, renderedFile, changeVersion, controlSize, disabled }: Props) {
  const dispatch = useAppDispatch();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const newVersionPropsUpload = {
    name: "file",
    multiple: true,
    disabled: disabled,
    accept: ".pdf",
    className: "new-document-versions-input",
    onChange(e) {
      const files =
        e.fileList?.map((file) => {
          if (file.originFileObj) {
            return file.originFileObj;
          }

          return file;
        }) || [];

      setFileList(files);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: () => false,
    fileList,
  };

  const renderDocumentVersions = () => {
    const versions = attachment?.finalizedData?.versions || [];
    const original = attachment?.filename;

    return [original, ...versions].map((doc, index) => {
      return (
        <Radio key={index} value={doc}>
          {getFileName(doc)} {index === 0 ? <Text italic>(original)</Text> : ""}
        </Radio>
      );
    });
  };

  const handleUpload = () => {
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append("files[]", file);
    });

    setUploading(true);

    dispatch(uploadNewDocumentVersion({ id: attachment.id, formData })).finally(() => {
      setFileList([]);
      setUploading(false);
      dispatch(fetchAuditLogByAttachmentId({ id: attachment.id }));

      if (isAttachment) {
        dispatch(fetchInvoice(attachment.id));
      } else {
        dispatch(fetchEmail(attachment.emailId));
        dispatch(fetchInvoices(attachment.emailId));
      }
    });
  };

  return (
    <div>
      {!isAttachment && <h2>Document Versions</h2>}
      {isAttachment && (
        <Typography.Title style={{ fontSize: 14 }} level={4}>
          Document Versions
        </Typography.Title>
      )}
      {attachment?.finalizedData && (
        <Radio.Group onChange={(e) => changeVersion(e.target.value)} value={renderedFile}>
          <Space style={{ marginTop: 9, marginBottom: 18, marginLeft: 11 }} direction="vertical">
            {renderDocumentVersions()}
          </Space>
        </Radio.Group>
      )}
      <Dragger {...newVersionPropsUpload}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file(s) to this area to upload</p>
        <p className="ant-upload-hint">Only .pdf supported.</p>
      </Dragger>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0 || disabled}
        loading={uploading}
        size={controlSize}
        style={{ width: 200, alignSelf: "center", marginTop: 10, marginBottom: 10 }}
      >
        {uploading ? "Uploading..." : "Upload New Versions"}
      </Button>
    </div>
  );
}

export default DocumentVersionsSection;
