import { useState } from "react";
import { Button, Typography } from "antd";

import { FilePdfOutlined, InboxOutlined } from "@ant-design/icons";

import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Attachment, fetchInvoice, uploadAdditionalDocuments } from "../store/invoicesSlice";
import Dragger from "antd/es/upload/Dragger";
import { useAppDispatch } from "../store";
import { fetchAuditLogByAttachmentId } from "../store/auditLogSlice";
import { fetchEmail, fetchInvoices } from "../store/emailsSlice";

interface Props {
  attachment: Attachment;
  isAttachment: boolean;
  controlSize: SizeType;
  disabled: boolean;
}

function AdditionalDocumentsSection({ attachment, isAttachment, controlSize, disabled }: Props) {
  const dispatch = useAppDispatch();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append("files[]", file);
    });

    setUploading(true);

    dispatch(uploadAdditionalDocuments({ id: attachment.id, formData })).finally(() => {
      setFileList([]);
      setUploading(false);
      dispatch(fetchAuditLogByAttachmentId({ id: attachment.id }));

      if (isAttachment) {
        dispatch(fetchInvoice(attachment.id));
      } else {
        dispatch(fetchEmail(attachment.emailId));
        dispatch(fetchInvoices(attachment.emailId));
      }
    });
  };

  const additionalDocumentsPropsUpload = {
    name: "file",
    multiple: true,
    accept: ".doc,.docx,.pdf",
    disabled: disabled,
    className: "additional-documents-input",
    onChange(e) {
      const files =
        e.fileList?.map((file) => {
          if (file.originFileObj) {
            return file.originFileObj;
          }

          return file;
        }) || [];

      setFileList(files);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: () => false,
    fileList,
  };

  const defineDocumentName = (doc, index) => {
    const uri = new URL(doc);
    const path = uri.pathname;
    const lengthToCheck = 92; // Length of "invoice-documents/" + 36 (GUID) + 1 (separator) + 36 (GUID)

    // Initialize the filename with the default value
    let filename = "file_" + (index+1);

    // Check if the path is long enough to include a filename
    if (path.length > lengthToCheck) {
      const potentialFileName = path.substring(lengthToCheck + 1); // +1 to skip the separator after GUIDs

      if (potentialFileName.includes(".")) {
        filename = decodeURIComponent(potentialFileName);
      }
    }

    return filename;
  }

  return (
    <div>
      {!isAttachment && <h2>Additional Documents</h2>}
      {isAttachment && (
        <Typography.Title style={{ fontSize: 14 }} level={4}>
          Additional Documents
        </Typography.Title>
      )}
      <ul style={{ listStyle: "none", padding: 0 }}>
        {attachment?.finalizedData?.documents?.map((doc, index) => {
          return (
            <li key={index} style={{ padding: "7px 18px" }}>
              <Typography.Link target="_blank" href={doc} rel="noreferrer">
                <FilePdfOutlined />
                <span style={{ marginInlineStart: 8 }}>
                  {" "}
                  { defineDocumentName(doc, index) }
                </span>
              </Typography.Link>
            </li>
          );
        })}
      </ul>
      <Dragger {...additionalDocumentsPropsUpload}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file(s) to this area to upload</p>
        <p className="ant-upload-hint">Only .pdf, .docx and .doc supported</p>
      </Dragger>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0 || disabled}
        loading={uploading}
        size={controlSize}
        style={{ width: 250, alignSelf: "center", marginTop: 10, marginBottom: 10 }}
      >
        {uploading ? "Uploading..." : "Upload Additional Documents"}
      </Button>
    </div>
  );
}

export default AdditionalDocumentsSection;
